import React from 'react'
import PropTypes from 'prop-types'

function Upload({ styling }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className={styling}
    >
      <title>Upload</title>
      <path d="m600 768c12.73 0 24.938-5.0586 33.941-14.059 9-9.0039 14.059-21.211 14.059-33.941v-351.96l73.199 73.078v0.003906c7.793 8.0078 18.094 13.105 29.188 14.438 11.094 1.332 22.309-1.1797 31.773-7.1172 12.176-7.8867 20.133-20.852 21.645-35.277 1.5156-14.426-3.5742-28.762-13.844-39.004l-92.762-92.762c-13.906-14.207-30.68-25.289-49.199-32.52-24.383-9.4141-50.98-11.531-76.547-6.1016-25.57 5.4336-49.004 18.188-67.453 36.703l-94.801 94.68c-10.078 10.062-15.168 24.082-13.895 38.266 1.2773 14.184 8.7852 27.07 20.496 35.176 9.8438 6.5703 21.707 9.4336 33.465 8.0703 11.762-1.3633 22.652-6.8594 30.734-15.512l72-72v351.84c0 12.73 5.0586 24.938 14.059 33.941 9.0039 9 21.211 14.059 33.941 14.059z" />
      <path d="m912 648c-12.73 0-24.938 5.0586-33.941 14.059-9 9.0039-14.059 21.211-14.059 33.941v144c0 6.3633-2.5273 12.469-7.0312 16.969-4.5 4.5039-10.605 7.0312-16.969 7.0312h-480c-6.3633 0-12.469-2.5273-16.969-7.0312-4.5039-4.5-7.0312-10.605-7.0312-16.969v-144c0-17.148-9.1484-32.996-24-41.57-14.852-8.5742-33.148-8.5742-48 0-14.852 8.5742-24 24.422-24 41.57v144c0 31.824 12.645 62.348 35.148 84.852 22.504 22.504 53.027 35.148 84.852 35.148h480c31.824 0 62.348-12.645 84.852-35.148 22.504-22.504 35.148-53.027 35.148-84.852v-144c0-12.73-5.0586-24.938-14.059-33.941-9.0039-9-21.211-14.059-33.941-14.059z" />
    </svg>
  )
}
// PROPTYPES
const { string } = PropTypes
Upload.propTypes = {
  styling: string,
}

export default Upload
