/* global CONFIGURATION_SOURCE, ENV_API_URL, ENV_AWS_CLIENT_ID, ENV_OIDC_AUTHORITY_URL, ENV_OAUTH2_REDIRECT_URL, ENV_END_SESSION_URL, ENV_SENTRY_DSN, ENV_SENTRY_ENVIRONMENT, ENV_SENTRY_RELEASE, ENV_SENTRY_TRACES_SAMPLE_RATE, ENV_SENTRY_ERROR_SAMPLE_RATE */
/* global SSI_API_URL, SSI_AWS_CLIENT_ID, SSI_OIDC_AUTHORITY_URL, SSI_OAUTH2_REDIRECT_URL, SSI_END_SESSION_URL, SSI_SENTRY_DSN, SSI_SENTRY_ENVIRONMENT, SSI_SENTRY_RELEASE, SSI_SENTRY_TRACES_SAMPLE_RATE, SSI_SENTRY_ERROR_SAMPLE_RATE */

let API_URL
let AWS_CLIENT_ID
let OIDC_AUTHORITY_URL
let OAUTH2_REDIRECT_URL
let END_SESSION_URL
let SENTRY_DSN
let SENTRY_ENVIRONMENT
let SENTRY_RELEASE
let SENTRY_TRACES_SAMPLE_RATE
let SENTRY_ERROR_SAMPLE_RATE

switch (CONFIGURATION_SOURCE) {
  case 'SSI':
  case 'Caddy':
    // they have been injected into the HTML
    API_URL = SSI_API_URL
    AWS_CLIENT_ID = SSI_AWS_CLIENT_ID
    OIDC_AUTHORITY_URL = SSI_OIDC_AUTHORITY_URL
    OAUTH2_REDIRECT_URL = SSI_OAUTH2_REDIRECT_URL
    END_SESSION_URL = SSI_END_SESSION_URL
    SENTRY_DSN = SSI_SENTRY_DSN
    SENTRY_ENVIRONMENT = SSI_SENTRY_ENVIRONMENT
    SENTRY_RELEASE = SSI_SENTRY_RELEASE
    SENTRY_TRACES_SAMPLE_RATE = SSI_SENTRY_TRACES_SAMPLE_RATE
    SENTRY_ERROR_SAMPLE_RATE = SSI_SENTRY_ERROR_SAMPLE_RATE
    break
  case 'Webpack':
  default:
    API_URL = ENV_API_URL
    AWS_CLIENT_ID = ENV_AWS_CLIENT_ID
    OIDC_AUTHORITY_URL = ENV_OIDC_AUTHORITY_URL
    OAUTH2_REDIRECT_URL = ENV_OAUTH2_REDIRECT_URL
    END_SESSION_URL = ENV_END_SESSION_URL
    SENTRY_DSN = ENV_SENTRY_DSN
    SENTRY_ENVIRONMENT = ENV_SENTRY_ENVIRONMENT
    SENTRY_RELEASE = ENV_SENTRY_RELEASE
    SENTRY_TRACES_SAMPLE_RATE = ENV_SENTRY_TRACES_SAMPLE_RATE
    SENTRY_ERROR_SAMPLE_RATE = ENV_SENTRY_ERROR_SAMPLE_RATE
    break
}

const GlobalConfiguration = {
  API_URL,
  AWS_CLIENT_ID,
  OIDC_AUTHORITY_URL,
  OAUTH2_REDIRECT_URL,
  END_SESSION_URL,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_RELEASE,
  SENTRY_TRACES_SAMPLE_RATE,
  SENTRY_ERROR_SAMPLE_RATE,
}

export default GlobalConfiguration
